<template>
  <div class="graphs-switcher">
    <div class="graphs-switcher__header">
      <slot name="header"/>
      <CyBtnToggle
        v-model="selectedGraph"
        :items="availableGraphs"
        active-theme="secondary"/>
    </div>

    <v-card class="graphs-switcher__container mt-4 rounded-lg">
      <v-card-text
        v-if="loading || !hideGraphs"
        class="graph pa-6">
        <div
          v-if="loading"
          class="graph--loading">
          <div class="graph-loader">
            <div class="graph-loader__bar"/>
            <div class="graph-loader__bar"/>
            <div class="graph-loader__bar"/>
            <div class="graph-loader__bar"/>
          </div>
          <span class="graph-loader__text my-2">
            {{ $t('loadingCostData') }}
          </span>
        </div>
        <CyCloudCostManagementCostChart
          v-else-if="!hideGraphs"
          :type="selectedGraph"
          :series="series"
          :totals="totals"/>
      </v-card-text>
      <slot name="footer"/>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import CyBtnToggle from '@/components/btn-toggle'
import CyCloudCostManagementCostChart from '@/components/cloud-cost-management/cost-chart'

export default {
  name: 'CyCloudCostManagementGraphsSwitcher',
  components: {
    CyBtnToggle,
    CyCloudCostManagementCostChart,
  },
  props: {
    context: {
      type: String,
      required: true,
    },
    graphs: {
      type: Array,
      default: () => ['line', 'bar', 'stacked-bar'],
    },
    hideGraphs: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    totals: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('organization/cloudCostManagement', {
      graphTypes: (state) => state.graphTypes,
      histogramDates: (state) => state.histogramDates,
    }),
    $static () {
      return {
        btnItems: [
          {
            key: 'line',
            value: 'line',
            icon: 'show_chart',
            disabled: this.histogramDates.length < 2,
            tooltip: this.histogramDates.length < 2 ? this.$t('disabledLineChart') : null,
          },
          {
            key: 'bar',
            value: 'bar',
            icon: 'bar_chart',
          },
          {
            key: 'stacked-bar',
            value: 'stacked-bar',
            icon: 'stacked_bar_chart',
          },
        ],
      }
    },
    availableGraphs () {
      return _.filter(this.$static.btnItems, ({ value }) => this.graphs.includes(value))
    },
    selectedGraph: {
      get () {
        return this.graphTypes[this.context] ?? this.graphs[0]
      },
      set (value) {
        this.SET_GRAPH_TYPE({ type: this.context, value })
      },
    },
  },
  mounted () {
    const graphTypes = JSON.parse(localStorage.getItem(LSK.CCM_GRAPHS_STATE) || '{}')
    if (!_.isEmpty(graphTypes.providerDetail)) this.SET_GRAPH_TYPE({ type: 'providerDetail', value: graphTypes.providerDetail })
    if (!_.isEmpty(graphTypes.topProjects)) this.SET_GRAPH_TYPE({ type: 'topProjects', value: graphTypes.topProjects })
  },
  methods: {
    ...mapMutations('organization/cloudCostManagement', [
      'SET_GRAPH_TYPE',
    ]),
  },
  i18n: {
    messages: {
      en: {
        disabledLineChart: 'Returned data spans too few dates to draw a line chart',
        loadingCostData: 'Loading cost data',
      },
      es: {
        disabledLineChart: 'Los datos devueltos no tienen suficientes fechas para dibujar correctamente un gráfico de líneas',
        loadingCostData: 'Cargando costes de datos',
      },
      fr: {
        disabledLineChart: 'Les données renvoyées ne chevauchent pas suffisament de dates pour tracer une courbe',
        loadingCostData: 'Chargement des données de coût',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.graphs-switcher {
  position: relative;

  &__header {
    display: flex;
    align-items: center;
  }

  &__container {
    overflow: hidden;

    .graph--loading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 400px;

      .graph-loader {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        width: 68px;
        height: 108px;

        &__bar {
          align-self: end;
          justify-self: center;
          width: 8px;
          margin-bottom: 0;
          border-radius: 2px;
          opacity: 0.5;

          --graph-bar-start-height: 72px;
          --graph-bar-end-height: 82px;

          &:nth-of-type(1) {
            height: 72px;
            animation: width 1.5s infinite;
            background-color: get-color("accent");

            --graph-bar-start-height: 72px;
            --graph-bar-end-height: 82px;
          }

          &:nth-of-type(2) {
            height: 108px;
            animation: width 1.5s infinite;
            background-color: get-color("secondary");

            --graph-bar-start-height: 108px;
            --graph-bar-end-height: 62px;
          }

          &:nth-of-type(3) {
            height: 62px;
            animation: width 1.5s infinite;
            background-color: #4285f4;

            --graph-bar-start-height: 62px;
            --graph-bar-end-height: 108px;
          }

          &:nth-of-type(4) {
            height: 82px;
            animation: width 1.5s infinite;
            background-color: #582026;

            --graph-bar-start-height: 82px;
            --graph-bar-end-height: 72px;
          }

          @keyframes width {
            0% {
              height: var(--graph-bar-start-height);
            }

            50% {
              height: var(--graph-bar-end-height);
            }

            100% {
              height: var(--graph-bar-start-height);
            }
          }
        }

        &__text {
          color: get-color("grey", "dark-2");
        }
      }
    }
  }
}
</style>
