<template>
  <v-chip
    label
    small
    :class="[
      `trend-chip trend-chip--${theme}`,
      { 'trend-chip--dark': dark }]">
    <v-icon>
      {{ icon }}
    </v-icon>
    <span v-if="label !== '--'">{{ label }}</span>
    <span v-else>
      <v-icon small>remove</v-icon>
      <v-icon small>remove</v-icon>
    </span>
  </v-chip>
</template>

<script>
export default {
  name: 'CyCloudCostManagementTrendChip',
  props: {
    value: {
      type: [Number, Array],
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    calculatedValue () {
      if (typeof this.value === 'number') {
        return {
          rounded: Math.round(this.value),
          raw: this.value,
        }
      }

      const [val0, val1] = this.value

      if (!val0) {
        if (!val1) return { raw: 0, rounded: 0 }
        return {
          raw: val1 < 0 ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY,
          rounded: null,
        }
      }

      return {
        raw: (val1 - val0) / val0 * 100,
        rounded: _.round((val1 - val0) / val0 * 100),
      }
    },
    label () {
      const { raw: rawValue, rounded: roundedValue } = this.calculatedValue
      const absRawValue = Math.abs(rawValue)

      if (!isFinite(rawValue)) return '--'
      if (absRawValue < 1 && absRawValue > 0) return `<01%`
      if (absRawValue > 999) return `>999%`
      return `${Math.abs(roundedValue).toString().padStart(2, 0)}%`
    },
    icon () {
      const { raw: trendValue } = this.calculatedValue
      if (trendValue < 0) return 'arrow_drop_down'
      if (trendValue === 0) return 'arrow_right'
      return 'arrow_drop_up'
    },
    theme () {
      const { raw: trendValue } = this.calculatedValue
      if (trendValue < 0) return 'success'
      if (trendValue === 0) return 'grey'
      return 'error'
    },
  },
  i18n: {
    messages: {
      en: {
        trendOver: 'Trend over {date}',
        totalCosts: 'Total costs',
      },
      es: {
        trendOver: 'Tendencia durante {date}',
        totalCosts: 'Costos totales',
      },
      fr: {
        trendOver: 'Tendance sur {date}',
        totalCosts: 'Coûts totaux',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.v-chip {
  padding-right: 4px !important;
  padding-left: 0 !important;
  opacity: 0;

  &--label {
    border-radius: 6px !important;
  }

  &.trend-chip {
    background: transparent;
    font-size: map.get($font-sizes, "base");
    font-weight: $font-weight-normal;

    &--success {
      color: get-color("success", "dark-1");

      .v-icon {
        color: get-color("success");
      }

      &:hover:not(.trend-chip--dark) {
        background: get-color("success", "light-2");

        .v-icon {
          color: get-color("success", "dark-1");
        }
      }
    }

    &--grey {
      color: get-color("primary", "light-2");

      .v-icon {
        color: get-color("primary", "light-3");
      }

      &:hover:not(.trend-chip--dark) {
        background: get-color("primary", "light-4");

        .v-icon {
          color: get-color("primary", "light-2");
        }
      }
    }

    &--error {
      color: get-color("error");

      .v-icon {
        color: get-color("error", "light-1");
      }

      &:hover:not(.trend-chip--dark) {
        background: get-color("error", "light-2");

        .v-icon {
          color: get-color("error", "light-1");
        }
      }
    }

    &--dark {
      &.trend-chip--success {
        color: get-color("success", "light-1");

        .v-icon {
          color: get-color("success", "light-2");
        }
      }

      &.trend-chip--grey {
        color: get-color("primary", "light-4");

        .v-icon {
          color: get-color("primary", "light-3");
        }
      }

      &.trend-chip--error {
        color: get-color("error", "light-1");

        .v-icon {
          color: get-color("error", "light-2");
        }
      }
    }
  }
}
</style>
