<template>
  <CyNotification
    v-if="activeTip"
    v-bind="$attrs"
    closeable
    :title="activeTip.title"
    :content="activeTip.content"
    @close="closeNotification"/>
</template>

<script>
export default {
  name: 'CyRandomTip',
  props: {
    lsPath: {
      type: String,
      default: 'tips/random',
    },
    renewalInterval: {
      type: Object,
      default: () => ({ days: 5 }),
    },
    tips: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    activeTip: null,
  }),
  mounted () {
    this.setActiveTip()
  },
  methods: {
    closeNotification () {
      this.activeTip = null
      localStorage.setItem(`${this.lsPath}/dismissal-date`, Date.now())
    },
    setActiveTip () {
      const randomTip = this.tips[_.random(0, this.tips.length - 1)]
      const lsTipsDismissalDate = parseInt(localStorage.getItem(`${this.lsPath}/dismissal-date`))
      const renewalDate = $date.add(lsTipsDismissalDate || Date.now(), this.renewalInterval)

      if (isNaN(lsTipsDismissalDate) || $date.isAfter(Date.now(), renewalDate)) {
        this.activeTip = randomTip
      }
    },
  },
}
</script>
