<template>
  <v-expansion-panel
    ref="expansionPanel"
    :class="['checkbox-filter', { 'single-filter': isSingleFilter }]">
    <v-expansion-panel-header
      v-if="!isSingleFilter"
      class="px-4 d-flex flex-wrap">
      <template #default>
        <span class="checkbox-filter__title text-truncate">
          {{ title }}
        </span>
        <div
          v-if="!_.isEmpty(selected)"
          class="checkbox-filter__selected mt-3">
          <CyTag
            small
            icon-after="close"
            class="checkbox-filter__selected-tag"
            @click-icon-after="handleTagClose">
            <span class="text-truncate">
              {{ (filterId === 'projects' && selectedTag === '') ? $t('noProject') : selectedTag }}
            </span>
          </CyTag>
          <v-chip
            x-small
            color="secondary"
            class="flex-shrink-0 ml-4">
            {{ selected.length }}
          </v-chip>
        </div>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content :class="isSingleFilter ? 'elevation-0' : 'elevation-4'">
      <div class="checkbox-filter__selected--empty px-4">
        <CyTag
          v-if="!isSingleFilter && _.isEmpty(selected)"
          variant="default"
          class="mb-3"
          small>
          {{ $t('noAppliedFilter') }}
        </CyTag>
      </div>
      <CyRandomTip
        v-if="canDisplayNamingConventionNotification"
        class="cy-notification--naming-convention"
        :ls-path="lsPath"
        :tips="[{
          title: $t('cloudCostManagement.namingConventions.title'),
          content: $t('cloudCostManagement.namingConventions.text'),
        }]"/>
      <div class="d-flex px-4">
        <v-icon
          v-if="isNested && selectedCategory"
          class="checkbox-filter__back-btn mb-2 mr-2 ml-n2 flex-shrink-0"
          @click="selectedCategory = null">
          arrow_back
        </v-icon>
        <v-text-field
          v-if="!_.isEmpty(visibleOptions) || (isNested && !selectedCategory)"
          v-model="search"
          append-icon="search"
          class="pt-0 mb-2"
          clearable
          :placeholder="$t('forms.fieldSearch')"
          hide-details/>
      </div>
      <div
        v-if="!isNested && !_.isEmpty(visibleOptions)"
        class="checkbox-filter__select-all px-4 py-3">
        <v-checkbox
          :input-value="isAllSelected"
          :label="$t('selectAll')"
          class="mt-0"
          color="secondary"
          hide-details
          @change="handleSelectAll"/>
        <v-chip x-small>
          {{ searchedOptions.length }}
        </v-chip>
      </div>
      <v-list
        v-if="!_.isEmpty(searchedOptions)"
        multiple
        class="checkbox-filter__options cy-scrollbars py-0"
        @change="isSingleFilter && $emit('input')">
        <v-list-item
          v-show="_.includes(searchedOptions, option)"
          v-for="option in visibleOptions"
          :key="`option-${option.value || option}`"
          :value="option.value || option"
          @click="optionClickHandler(option.value || option)">
          <v-list-item-action v-if="!categoryDisplay">
            <v-checkbox
              :input-value="isOptionSelected(option)"
              color="secondary"
              class="text-truncate"
              hide-details/>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="d-inline align-center">
              <slot
                name="item"
                :props="option">
                <CyTooltip
                  top
                  :disabled="getOptionValue(option) && getOptionValue(option).length < 31">
                  <template #activator="{ on }">
                    <span v-on="on">
                      {{ getOptionValue(option) }}
                    </span>
                  </template>
                  {{ getOptionValue(option) }}
                </CyTooltip>
              </slot>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon v-if="categoryDisplay">
            <v-icon>chevron_right</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
      <div
        v-else-if="search"
        class="checkbox-filter__options--no-matches pa-4">
        <span>{{ $t('noFilterMatches') }}</span>
      </div>
      <div
        v-else
        class="checkbox-filter__options--empty pa-4">
        <span>{{ $t('noAvailableFilters') }}</span>
      </div>
      <div
        v-if="!isSingleFilter"
        class="checkbox-filter__actions pa-2">
        <CyButton
          variant="tertiary"
          theme="grey"
          sm
          @click="clear">
          {{ $t('forms.btnClear') }}
        </CyButton>
        <CyButton
          sm
          @click="$emit('input')">
          {{ $t('applyFilters') }}
        </CyButton>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import CyRandomTip from '@/components/random-tip'

export default {
  name: 'CyCloudCostManagementSidebarCheckboxFilter',
  components: {
    CyRandomTip,
  },
  props: {
    filterId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    options: {
      type: [Array, Object],
      required: true,
    },
    isSingleFilter: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    search: '',
    selectedCategory: null,
  }),
  computed: {
    ...mapState('organization/cloudCostManagement', {
      queryBody: (state) => state.queryBody,
    }),
    selected: {
      get () {
        return _.get(this.queryBody, this.filterId, [])
      },
      set (value) {
        this.SET_QUERY_FILTER({ key: this.filterId, value })
      },
    },
    categoryDisplay () {
      return this.isNested && !this.selectedCategory
    },
    isAllSelected () {
      const searchedValues = _.map(this.searchedOptions, (option) => option.value || option)
      return !_.isEmpty(this.selected) && !_.isEmpty(searchedValues) && _.isEmpty(_.difference(searchedValues, this.selected))
    },
    canDisplayNamingConventionNotification () {
      return this.$route.params.providerCanonical === 'gcp' && this.title === 'Tags'
    },
    isNested () {
      return this.filterId === 'tags'
    },
    lsPath () {
      return LSK.CCM_TAGS_NOTIFICATIONS
    },
    searchedOptions () {
      return _.filter(this.visibleOptions, this.searchMethod)
    },
    selectedTag () {
      const lastSelection = _.last(this.selected)
      if (this.isNested) return lastSelection.split(';')[1]
      if (_.isString(_.head(this.options))) {
        return lastSelection
      }
      return _.find(this.options, ['value', lastSelection])?.label || lastSelection
    },
    visibleOptions () {
      if (!this.isNested) return this.options
      if (!this.selectedCategory) return _.keys(this.options)
      return _.get(this.options, this.selectedCategory, [])
    },
  },
  watch: {
    selectedCategory () {
      this.search = ''
    },
  },
  created () {
    window.addEventListener('click', (e) => {
      if (this.$refs.expansionPanel?.isActive && !this.$el.contains(e.target)) {
        this.READ_URL_QUERY()
      }
    })
  },
  methods: {
    ...mapMutations('organization/cloudCostManagement', [
      'SET_QUERY_FILTER',
      'READ_URL_QUERY',
    ]),
    getOptionValue (option) {
      return (this.filterId === 'projects' && option === '') ? this.$t('noProject') : option
    },
    searchMethod (option) {
      const value = option.label || _.replace(option, /^$/, this.$t('noProject'))
      return !this.search || value.toLowerCase().includes(this.search.toLowerCase())
    },
    clear () {
      this.selected = []
      this.$emit('input')
    },
    handleSelectAll () {
      const searchedValues = _.map(this.searchedOptions, (option) => option.value || option)
      this.selected = this.isAllSelected ? _.difference(this.selected, searchedValues) : searchedValues
    },
    handleTagClose () {
      this.selected = this.selected.slice(0, -1)
      if (!this.$refs.expansionPanel?.isActive) this.$emit('input')
    },
    optionClickHandler (option) {
      const value = this.isNested ? `${this.selectedCategory};${option}` : option
      if (!this.categoryDisplay) {
        this.selected = _.xor(this.selected, [value])
        return
      }
      this.selectedCategory = option
    },
    isOptionSelected (option) {
      if (this.isNested) return this.selected.includes(`${this.selectedCategory};${option}`)
      return this.selected.includes(option.value || option)
    },
  },
  i18n: {
    messages: {
      en: {
        applyFilters: 'Apply filters',
        noAppliedFilter: 'No filter applied',
        noAvailableFilters: 'No available filters',
        noFilterMatches: 'No filters match your search',
        noProject: '@:cloudCostManagement.noProject',
        selectAll: 'Select all',
      },
      es: {
        applyFilters: 'Aplicar filtros',
        noAppliedFilter: 'No se aplicó ningún filtro',
        noAvailableFilters: 'No hay filtros disponibles',
        noFilterMatches: 'No hay filtros que coincidan con su búsqueda',
        noProject: '@:cloudCostManagement.noProject',
        selectAll: 'Seleccionar todo',
      },
      fr: {
        applyFilters: 'Appliquer les filtres',
        noAppliedFilter: 'Aucun filtre appliqué',
        noAvailableFilters: 'Aucun filtre disponible',
        noFilterMatches: 'Aucun filtre ne correspond à votre recherche',
        noProject: '@:cloudCostManagement.noProject',
        selectAll: 'Sélectionner tout',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import "styles/variables/cymenu";

.checkbox-filter {
  border-radius: 4px;

  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0;
  }

  ::v-deep .v-expansion-panel-header {
    font-size: map.get($font-sizes, "base");

    &__icon {
      .v-icon {
        margin-right: -4px;
        font-size: 30px;
      }
    }

    &--active {
      min-height: 0;

      &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 3;
        right: 0;
        bottom: 0;
        left: 0;
        height: 6px;
        background: get-color("white");
      }
    }
  }

  ::v-deep .v-expansion-panel-content {
    position: absolute;
    z-index: 2;
    width: 100%;
    border-radius: 0 0 $menu-border-radius $menu-border-radius;
    background-color: get-color("white");

    &.expand-transition-leave-active {
      z-index: 1;
    }
  }

  &__options,
  &__categories {
    max-height: 260px;
    border-top: 1px solid get-color("grey");
    border-bottom: 1px solid get-color("grey");
    border-radius: 0 0 4px 4px;

    ::v-deep .v-list-item--active::before {
      opacity: 0;
    }

    &--empty,
    &--no-matches {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 120px;
      border-top: 1px solid get-color("grey");
      border-bottom: 1px solid get-color("grey");
    }
  }

  &.single-filter {
    ::v-deep .v-expansion-panel-content {
      position: relative;
    }

    .checkbox-filter {
      &__options,
      &__categories {
        border-bottom: none;
      }
    }
  }

  &__title {
    width: calc(100% - 26px);
    line-height: 1.5;
  }

  &__back-btn {
    width: 36px;
  }

  &__selected {
    display: flex;
    justify-content: space-between;
    order: 1;
    width: 100%;

    &-tag.tag {
      display: grid;
      grid-template-columns: 1fr 24px;
    }

    &--empty {
      order: 1;
      width: 100%;
    }
  }

  &__select-all {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
  }

  .cy-notification--naming-convention {
    margin-bottom: 7px;
    border-radius: 0;
  }
}
</style>
