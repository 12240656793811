<template>
  <CyInputsDateTimeRange
    ref="dateTimeRangeInput"
    :relative-options="$static.options"
    :value="value"
    mandatory
    @change="inputChangeHandler">
    <template #activator="{ on, label }">
      <div
        class="date-range-filter px-4 py-3"
        v-on="on">
        <div>
          <div class="date-range-filter__title mb-2">
            {{ $t('dateRange') }}
          </div>
          {{ label }}
        </div>
        <v-icon>arrow_drop_down</v-icon>
      </div>
    </template>
  </CyInputsDateTimeRange>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import CyInputsDateTimeRange from '@/components/inputs/date-time-range'
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment

export default {
  name: 'CyCloudCostManagementSidebarDateRangeFilter',
  components: {
    CyInputsDateTimeRange,
  },
  computed: {
    ...mapState('organization/cloudCostManagement', {
      queryBody: (state) => state.queryBody,
    }),
    $static () {
      return {
        options: [
          {
            label: this.$t('forms.field.timeRangeLastWeek'),
            value: '1w',
          },
          {
            label: this.$tc('forms.field.timeRangeLastMonths', 1),
            value: '1M',
          },
          {
            label: this.$tc('forms.field.timeRangeLastMonths', 3, { months: 3 }),
            value: '3M',
          },
          {
            label: this.$t('forms.field.timeRangeLastYear'),
            value: '1y',
          },
        ],
      }
    },
    value: {
      get () {
        return _.mapValues(_.pick(this.queryBody, ['begin', 'end']), (dateString) => moment(dateString, 'YYYY-MM-DD').valueOf())
      },
      set ({ value: { begin, end } }) {
        this.SET_QUERY_FILTER({ key: 'begin', value: moment(begin).format('YYYY-MM-DD') }) // eslint-disable-line you-dont-need-momentjs/format
        this.SET_QUERY_FILTER({ key: 'end', value: moment(end).format('YYYY-MM-DD') }) // eslint-disable-line you-dont-need-momentjs/format
        if (moment(begin).add(3, 'months').isBefore(moment(end))) { // eslint-disable-line you-dont-need-momentjs/add, you-dont-need-momentjs/is-before
          this.SET_QUERY_FILTER({ key: 'granularity', value: 'month' })
        }
        this.$emit('input')
      },
    },
  },
  methods: {
    ...mapMutations('organization/cloudCostManagement', [
      'SET_QUERY_FILTER',
    ]),
    inputChangeHandler (value) {
      this.value = value
      this.$refs.dateTimeRangeInput.isOpen = false
    },
  },
  i18n: {
    messages: {
      en: {
        dateRange: 'Date range',
      },
      es: {
        dateRange: 'Rango de fechas',
      },
      fr: {
        dateRange: 'Plage de dates',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .date-range-filter {
    display: flex;
    justify-content: space-between;
    border: 1px solid get-color("grey");
    border-radius: 4px;
    background-color: get-color("white");
    cursor: pointer;

    .v-icon {
      color: get-color("black");
    }

    &__title {
      color: get-color("grey", "dark-2");
      font-size: map.get($font-sizes, "sm");
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }
  }
</style>
